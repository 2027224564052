import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://8aecc5cf72c5405cb015e6db224b1f9e@o543623.ingest.sentry.io/6065360',
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event) {
    const exceptions = event.exception
    if (exceptions?.values?.length) {
      const exception = exceptions.values[exceptions.values.length - 1]
      if (exception.mechanism?.handled) {
        return
      }
    }
    return event
  },
})
