import 'styles/theme.scss'
import 'styles/global.css'
import '@assets/chrome-bug.css'
import 'styles/global.css'

import { FC, useEffect } from 'react'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import { GlobalProvider } from 'contexts'
import { UserProvider } from 'contexts/user-context'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import theme from '../services/theme'
import createEmotionCache from '../utils/createEmotionCache'
import { OrdersProvider } from 'contexts/orders-context'
import { SubscriptionsProvider } from 'contexts/subscriptions-context'
import Script from 'next/script'

const setGoogleTags = (id) => ({
  __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');
  `,
})

const Noop: FC = ({ children }) => <>{children}</>

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) {
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document?.body?.classList?.remove?.('loading')
    }
  })

  useEffect(() => {
    try {
      if (globalThis.top && globalThis.location !== globalThis.top.location) {
        // breakout of iframes
        console.error(
          `iFrame on ${globalThis.location?.pathname} failed to load or 404'd`
        )
        const tries = ((params) => {
          const _tries =
            params
              .split(/[?&]/)
              .map((pair) => pair.split('='))
              .find((pair) => pair[0] === 'tries')?.[1] ?? 0
          return Number(_tries)
        })(globalThis.top.location.search)
        globalThis.top.location.assign(
          `${globalThis.top.location.protocol}//${
            globalThis.top.location.host
          }${globalThis.top.location.pathname}?tries=${tries + 1}`
        )
      }
    } catch (err) {
      console.error(err)
    }
  })

  const handleKustmer = () => {
    window.Kustomer.start({
      brandId: '6585d368c5c1721aa73835c6',
    })
  }

  const criteoUrl = `//dynamic.criteo.com/js/ld/ld.js?a=${process.env.NEXT_PUBLIC_CRITEO_ACCOUNT}`

  return (
    <CacheProvider value={emotionCache}>
      {process.env.NODE_ENV === 'production' && (
        <Head>
          <script dangerouslySetInnerHTML={setGoogleTags('GTM-5JJXF3T')} />
          <script src="/scripts/tiktok.js" />
          <script src="/scripts/pinterest.js" />
          <script src="/scripts/twitter.js" />
          <script
            async
            src="https://static.mobilemonkey.com/js/mm_92e62e7d-459d-4405-8944-ec2f7412d69b-77930543.js"
          ></script>
          {process.env.NEXT_PUBLIC_QUANTUM_METRIC_LIVE === 'true' && (
            <script src="/scripts/quantum.js" />
          )}
          <script type="text/javascript" src={criteoUrl} async={true}></script>
          <script
            src="https://cdn-widgetsrepository.yotpo.com/v1/loader/iiyNDpMXOwx3vAVpO7qxlQ"
            async
          ></script>
        </Head>
      )}
      {process.env.NEXT_PUBLIC_PRODUCTION === 'true' && (
        <Script
          src="https://cdn.kustomerapp.com/chat-web/widget.js"
          type="text/javascript"
          data-kustomer-api-key={process.env.NEXT_PUBLIC_KUSTOMER_API_KEY}
          onLoad={handleKustmer}
        />
      )}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalProvider>
          <UserProvider>
            {process.env.NODE_ENV === 'production' && (
              <>
                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-KT646QG"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                  />
                </noscript>
                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-5JJXF3T"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                  ></iframe>
                </noscript>
              </>
            )}
            <OrdersProvider>
              <SubscriptionsProvider>
                <Layout pageProps={pageProps}>
                  {/* @ts-ignore */}
                  <Component {...pageProps} />
                </Layout>
              </SubscriptionsProvider>
            </OrdersProvider>
          </UserProvider>
        </GlobalProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}
