import React, { createContext, useContext, useEffect, useReducer } from 'react'
import api from '../services/api'
import { Order } from 'types/orders'
import { UserContext } from './user-context'
import Cookies from 'js-cookie'
import { TOKEN } from 'constants/cookies'

type OrdersState = { orders: Order[] }
type OrdersAction =
  | {
      type: 'SET_ORDERS'
      payload: OrdersState
    }
  | {
      type: 'CLEAR_ORDERS'
    }
type OrdersContextType = {
  state: OrdersState
  dispatch: React.Dispatch<OrdersAction>
}

const initialState: OrdersState = {
  orders: [],
}

export const OrdersContext = createContext<OrdersContextType>({
  dispatch: () => null,
  state: initialState,
})

const ordersReducer = (
  state: OrdersState,
  action: OrdersAction
): OrdersState => {
  switch (action.type) {
    case 'SET_ORDERS':
      return {
        ...state,
        orders: action.payload.orders,
      }
    case 'CLEAR_ORDERS':
      return {
        orders: [],
      }
    default:
      throw new Error('Action type not found.')
  }
}

export const OrdersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ordersReducer, initialState)
  const { state: userState } = useContext(UserContext)

  useEffect(() => {
    let isMounted = true
    dispatch({
      type: 'CLEAR_ORDERS',
    })

    const fetchOrders = async () => {
      if (userState.user && Cookies.get(TOKEN)) {
        try {
          const ordersData = await api.getOrders(1, userState.user.id)
          const { data } = ordersData.data
          if (isMounted) {
            dispatch({
              type: 'SET_ORDERS',
              payload: { orders: data },
            })
          }
        } catch (e) {
          console.error(`OrdersProvider/fetchOrders - ${e}`)
        }
      }
    }

    fetchOrders()

    return () => {
      isMounted = false
    }
  }, [userState.user])

  return (
    <OrdersContext.Provider value={{ dispatch, state }}>
      {children}
    </OrdersContext.Provider>
  )
}
