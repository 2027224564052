import { createContext, useContext, useEffect, useReducer } from 'react'
import {
  SubscriptionsContextType,
  SubscriptionsAction,
  SubscriptionsContextState,
} from '../types/subscriptions-context'
import { UserContext } from './user-context'
import Cookies from 'js-cookie'
import { TOKEN } from 'constants/cookies'
import _ from 'lodash'
import { fetchSubscriptions } from '@utils/subscriptionMetaDataLoader'

const initialState: SubscriptionsContextState = {
  activeSubscriptions: [],
  inactiveSubscriptions: [],
  activeSubscriptionsLTV: [],
}

export const SubscriptionsContext = createContext<SubscriptionsContextType>({
  dispatch: () => null,
  state: initialState,
})

const storeReducer = (
  state: SubscriptionsContextState,
  action: SubscriptionsAction
): SubscriptionsContextState => {
  switch (action.type) {
    case 'CLEAR_SUBSCRIPTIONS': {
      return {
        activeSubscriptions: [],
        inactiveSubscriptions: [],
        activeSubscriptionsLTV: [],
      }
    }
    case 'SET_ACTIVE_SUBSCRIPTIONS':
      return {
        ...state,
        activeSubscriptions: action.payload,
      }
    case 'SET_INACTIVE_SUBSCRIPTIONS':
      return {
        ...state,
        inactiveSubscriptions: action.payload,
      }
    default:
      throw new Error('Action type not found.')
  }
}

export const SubscriptionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState)
  const { state: userState } = useContext(UserContext)

  const fetchSubs = () => {
    if (
      !!userState?.user?.bcCustomerId &&
      !_.isNull(userState?.user?.bcCustomerId) &&
      Cookies.get(TOKEN)
    ) {
      fetchSubscriptions(String(userState.user.bcCustomerId), dispatch)
    }
  }

  useEffect(() => {
    let isMounted = true
    dispatch({
      type: 'CLEAR_SUBSCRIPTIONS',
    })

    fetchSubs()

    return () => {
      isMounted = false
    }
  }, [userState.user])

  return (
    <SubscriptionsContext.Provider value={{ dispatch, state }}>
      {children}
    </SubscriptionsContext.Provider>
  )
}
